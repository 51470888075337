import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Navbar.css';
import { AiOutlineMenu } from "react-icons/ai";
import { IoIosArrowForward, IoMdNotifications } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import SpinnerModal from '../Modales/SpinnerModal/SpinnerModal';
import ModalNotificaciones from '../Modales/ModalNotificaciones/ModalNotificaciones';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            dropdown1ver: false,
            dropdown2ver: false,
            dropdown3ver: false,
            dropdown4ver: false,
            dropdown5ver: false,
            dropdown6ver: false,
            dropdown7ver: false,
            dropdown8ver: false,
            dropdown8verU: false,
            dropdown9ver: false,
            dropdown10ver: false,
            dropdown10veruser: false,
            dropdown10vernomina: false,
            dropdownuser: false,
            dropdown10verreportes: false,
            dropdownranchosver:false,

            dropdownproductos: false,
            dropdowncompras: false,
            dropdownoperaciones: false,
            dropdownalmacenes: false,
            dropdownactividades: false,
            dropdowncosechas: false,
            vercollagemovil: false,
            versubmenumovil: false,

            arreglotitulos: [

                {
                    titulo: '',
                    ruta: '#',
                    tienesubmenu: false,
                },
                {
                    titulo: '',
                    ruta: '#',
                    tienesubmenu: false,
                },
                {
                    titulo: 'Ventas',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [                        
                       /* {
                            subtitulo: 'Corrida de limón',
                            ruta: '/catalogocorridalimon',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Cotización de naranja',
                            ruta: '/catalogocotizacionnaranja',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Cotización de toronja',
                            ruta: '/catalogocotizaciontoronja',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]

                        },*/
                        {
                            subtitulo: 'Ventas',
                            ruta: '/catalogoventas',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]

                        }
                    ]
                },
                {
                    titulo: 'Almacenes',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Agroquímicos',
                            ruta: '/catalogoalmacenes',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Combustibles',
                            ruta: '/catalogoalmacenescombustible',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Equipos y herramientas',
                            ruta: '/catalogoalmacenesequiposyherramientas',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Activos fijos',
                            ruta: '/catalogoalmacenesproductosactivos',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Productos generales',
                            ruta: '/catalogoalmacenesgenerales',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                    ]
                },

                {
                    titulo: 'Compras',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Compras',
                            ruta: '/catalogocompras',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Requisiciones agroquímicos',
                            ruta: '/catalogorequisicionesagroquimicos',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Requisiciones productos generales',
                            ruta: '/catalogorequisicionesgenerales',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Requisiciones servicios',
                            ruta: '/catalogorequisicionesservicios',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Vales de combustibles',
                            ruta: '/catalogovalescombustibles',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },

                    ]
                },
                {
                    titulo: 'Operación',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Órdenes de trabajo',
                            ruta: '/catalogoordentrabajo',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        }

                    ]
                },
                {
                    titulo: 'Cosecha',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                       /* {
                            subtitulo: 'Bitácora de cosechas',
                            ruta: '/catalogobitacoracosecha',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Bitácora de cuadrillas',
                            ruta: '/catalogobitacoracuadrillas',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Bitácora de traslados',
                            ruta: '/catalogobitacoratraslados',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Bitácora de recepción',
                            ruta: '/catalogobitacorarecepcionfruta',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },  */                      
                        {
                            subtitulo: 'Orden de cosecha',
                            ruta: '/catalogoordencosecha',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        

                    ]
                },
                {
                    titulo: 'Presupuestos',
                    ruta: '/catalogopresupuestos',
                },
                {
                    titulo: 'Nómina',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Nómina',
                            ruta: '/catalogonomina',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Nómina Seguridad',
                            ruta: '/catalogonominaseguridad',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Salarios',
                            ruta: '/catalagosalarios',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                    ]
                },
                {
                    titulo: 'Reportes',
                    ruta: '#',
                    tienesubmenu: false,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Reporte compra',
                            ruta: '/Catalogoreportes',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        }
                    ]
                },
                {
                    titulo: 'Gestión',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [

                        {
                            subtitulo: 'Clientes',
                            ruta: '/catalogoclientes',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Cuadrillas',
                            ruta: '/catalogocuadrillas',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Cultivos',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [
                                {
                                    titulo: 'Estatus siembra',
                                    ruta: '/catalogoestatussiembra'
                                }, {
                                    titulo: 'Patrones injerto',
                                    ruta: '/catalogopatronesinjerto'
                                },
                                {
                                    titulo: 'Tipo de suelo',
                                    ruta: '/catalogotiposuelo'
                                }
                            ]
                        },

                        {
                            subtitulo: 'Familias',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [

                                {
                                    titulo: 'Familias agroquímicos',
                                    ruta: '/catalogofamiliaagroquimicos'
                                },
                            ]
                        },
                        {
                            subtitulo: 'Generales',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [
                                {
                                    titulo: 'Actividades',
                                    ruta: '/catalogoactividades'
                                },
                                {
                                    titulo: 'Áreas de trabajo',
                                    ruta: '/catalogoareastrabajo'
                                },
                                {
                                    titulo: 'Combustibles',
                                    ruta: '/catalogocombustible'
                                },
                                {
                                    titulo: 'Cultivos',
                                    ruta: '/catalogocultivos'
                                },
                                {
                                    titulo: 'Documentos',
                                    ruta: '/catalogodocumentos'
                                },
                                {
                                    titulo: 'Equipos',
                                    ruta: '/catalogoequipos'
                                },
                                {
                                    titulo: 'IEPS',
                                    ruta: '/catalogoieps'
                                },
                                {
                                    titulo: 'Ingredientes activos',
                                    ruta: '/catalogoingredientesactivos'
                                },
                                {
                                    titulo: 'Maquinaria/vehículos',
                                    ruta: '/catalogomaquinaria'
                                },
                                /* {
                                     titulo: 'Movimientos de almacén',
                                     ruta: '/catalogomovimientosalmacen'
                                 },*/
                                {
                                    titulo: 'Plagas',
                                    ruta: '/catalogoplagas'
                                },
                                {
                                    titulo: 'Presentación de producto',
                                    ruta: '/catalogopresentacionproductos'
                                },
                                {
                                    titulo: 'Puestos de trabajo',
                                    ruta: '/catalogopuestostrabajo'
                                },
                                {
                                    titulo: 'Unidades de medida',
                                    ruta: '/catalogounidadesdemedida'
                                },

                            ]
                        },
                        
                       
                        {
                            subtitulo: 'Productos',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [
                                {
                                    titulo: 'Agroquímicos',
                                    ruta: '/catalogoagroquimicos'
                                },
                                {
                                    titulo: 'Productos generales',
                                    ruta: '/catalogoproductosgenerales'
                                },
                            ]
                        },
                        {
                            subtitulo: 'Proveedores',
                            ruta: '/catalogoproveedores',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        },
                        {
                            subtitulo: 'Ranchos',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [
                                
                                {
                                    titulo: 'Lotes',
                                    ruta: '/catalogolotescultivo'
                                },{
                                    titulo: 'Ranchos',
                                    ruta: '/catalogoranchoscultivo'
                                },{
                                    titulo: 'Tablas',
                                    ruta: '/catalogotablacultivo'
                                },{
                                    titulo: 'Zonas',
                                    ruta: '/catalogozonacultivo'
                                },
                            ]
                        },

                        {
                            subtitulo: 'Tipos',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [


                                {
                                    titulo: 'Tipo actividad',
                                    ruta: '/catalogotipoactividades'
                                },
                                {
                                    titulo: 'Tipo agroquímicos',
                                    ruta: '/catalogotipoagroquimicos'
                                },
                                {
                                    titulo: 'Tipo clientes',
                                    ruta: '/catalogotipoclientes'
                                },
                                {
                                    titulo: 'Tipo cultivos',
                                    ruta: '/catalogotipocultivos'
                                },
                                {
                                    titulo: 'Tipo documentos',
                                    ruta: '/catalogotipodocumentos'
                                },
                                {
                                    titulo: 'Tipo equipos',
                                    ruta: '/catalogotipoequipos'
                                },
                                {
                                    titulo: 'Tipo maquinaria/vehículos',
                                    ruta: '/catalogotipomaquinaria'
                                },
                                {
                                    titulo: 'Tipo plagas',
                                    ruta: '/catalogotipoplagas'
                                },
                                {
                                    titulo: 'Tipo producto general',
                                    ruta: '/catalogotipoproductogeneral'
                                },

                                {
                                    titulo: 'Tipo proveedores',
                                    ruta: '/catalogotipoproveedores'
                                },
                                {
                                    titulo: 'Tipo requisiciones de agroquímicos',
                                    ruta: '/catalogotiporequisicion'
                                },
                                {
                                    titulo: 'Tipo requisiciones de generales',
                                    ruta: '/catalogotiporequisiciongeneral'
                                },
                                {
                                    titulo: 'Tipo requisiciones de servicios',
                                    ruta: '/catalogotiporequisicionservicios'
                                },
                                
                               

                            ]
                        },
                        {
                            subtitulo: 'Usuarios',
                            ruta: '#',
                            subtienesubmenu: true,
                            subvertienesubmenu: false,
                            subnivel: [
                                {
                                    titulo: 'Asignar a rancho',
                                    ruta: '/catalogoasignarusuarios'
                                },                                
                                {
                                    titulo: 'Niveles autorización',
                                    ruta: '/catalogonivelesautorizacion'
                                },
                                {
                                    titulo: 'Tipos usuarios',
                                    ruta: '/catalogotipousuarios'
                                },
                                {
                                    titulo: 'Usuarios',
                                    ruta: '/catalogousuarios'
                                },
                            ]
                        },
                        


                    ]
                },
                {/*
                    titulo: 'Actividades',
                    ruta: '#',
                    tienesubmenu: true,
                    vertienesubmenu: false,
                    submenu: [
                        {
                            subtitulo: 'Órdenes de trabajo',
                            ruta: '/catalogoordentrabajo',
                            subtienesubmenu: false,
                            subvertienesubmenu: false,
                            subnivel: [
                            ]
                        }
                    ]
                */},

                {
                    titulo: 'Perfil',
                    ruta: '/perfilusuario'
                },

                {
                    titulo: 'Cerrar Sesión',
                    ruta: 'cerrar'
                },
            ],
            salir: false,

            navegar: false,
            rutaseleccionada: "",
            mostrarnotificaciones: false,
        };

    }

   
  componentWillUnmount() {
    // Limpiar los event listeners cuando el componente se desmonte
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('wheel', this.handleWheel);
  }

  handleKeyDown = (e) => {
    if (e.target && e.target.type === 'number') {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }
    }
  };

  handleWheel = (e) => {

    if (e.target && e.target.type === 'number') {

      e.preventDefault();
    }
  };



async componentDidMount() {

     // Escuchar todos los cambios de keydown en inputs numéricos
     document.addEventListener('keydown', this.handleKeyDown);
     document.addEventListener('wheel', this.handleWheel, { passive: false });


        window.addEventListener("resize", this.dimensiones)
        if (await localStorage.getItem('ls') == null || await localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
            return;
        }
    }

    dimensiones = () => {
        if (window.innerWidth > 991) {
            this.setState({ vercollagemovil: false })
        }
    }
    verSalir = () => {
        this.setState(prevState => ({
            dropdown4ver: !prevState.dropdown4ver
        }));
    }
    verMenu = () => {
        this.setState(prevState => ({
            dropdown1ver: !prevState.dropdown1ver
        }));
    }
    verMenuAlmacenes = () => {
        this.setState(prevState => ({
            dropdownalmacenes: !prevState.dropdownalmacenes
        }));
    }
    verMenuUsuarios = () => {
        this.setState(prevState => ({
            dropdown10veruser: !prevState.dropdown10veruser
        }));
    }
    verMenuNomina = () => {
        this.setState(prevState => ({
            dropdown10vernomina: !prevState.dropdown10vernomina
        }));
    }
    verMenuReportes = () => {
        this.setState(prevState => ({
            dropdown10verreportes: !prevState.dropdown10verreportes
        }));
    }
    verMenuProductos = () => {
        this.setState(prevState => ({
            dropdownproductos: !prevState.dropdownproductos
        }));
    }
    verMenuComrpas = () => {
        this.setState(prevState => ({
            dropdowncompras: !prevState.dropdowncompras
        }));
    }
    verMenuOperaciones = () => {
        this.setState(prevState => ({
            dropdownoperaciones: !prevState.dropdownoperaciones
        }));
    }
    verMenuCosechas = () => {
        this.setState(prevState => ({
            dropdowncosechas: !prevState.dropdowncosechas
        }));
    }
    verMenuGestios = () => {
        this.setState(prevState => ({
            dropdown5ver: !prevState.dropdown5ver
        }));
    }
    verSubMenu = () => {
        this.setState(prevState => ({
            dropdown2ver: !prevState.dropdown2ver
        }));
    }
    verSubMenu2 = () => {
        this.setState(prevState => ({
            dropdown3ver: !prevState.dropdown3ver
        }));
    }
    verSubMenuGestion0 = () => {
        this.setState(prevState => ({
            dropdown6ver: !prevState.dropdown6ver
        }));
    }
    verSubMenuGestion1 = () => {
        this.setState(prevState => ({
            dropdown7ver: !prevState.dropdown7ver
        }));
    }
    verSubMenuGestion2 = () => {
        this.setState(prevState => ({
            dropdown8ver: !prevState.dropdown8ver
        }));
    }
    verSubMenuGestion2Usuario = () => {
        this.setState(prevState => ({
            dropdown8verU: !prevState.dropdown8verU
        }));
    }
    verSubMenuGestion2Producto = () => {
        this.setState(prevState => ({
            dropdown10verProd: !prevState.dropdown10verProd
        }));
    }
    verMenuActividades = () => {
        this.setState(prevState => ({
            dropdownactividades: !prevState.dropdownactividades
        }));
    }



    onMouseEnter2 = () => {
        this.setState({ dropdown2ver: true });
    }

    onMouseLeave2 = () => {
        this.setState({ dropdown2ver: false });
    }

    onMouseEnter3 = () => {
        this.setState({ dropdown3ver: true });
    }

    onMouseLeave3 = () => {
        this.setState({ dropdown3ver: false });
    }

    onMouseEnter4 = () => {
        this.setState({ dropdown4ver: true });
    }

    onMouseLeave4 = () => {
        this.setState({ dropdown4ver: false });
    }

    onMouseEnter5 = () => {
        this.setState({ dropdown5ver: true });
    }

    onMouseLeave5 = () => {
        this.setState({ dropdown5ver: false });
    }

    onMouseEnter6 = () => {
        this.setState({ dropdown6ver: true });
    }

    onMouseLeave6 = () => {
        this.setState({ dropdown6ver: false });
    }

    onMouseEnter7 = () => {
        this.setState({ dropdown7ver: true });
    }

    onMouseLeave7 = () => {
        this.setState({ dropdown7ver: false });
    }

    onMouseEnter8 = () => {
        this.setState({ dropdown8ver: true });
    }

    onMouseLeave8 = () => {
        this.setState({ dropdown8ver: false });
    }
    onMouseEnter8Usuario = () => {
        this.setState({ dropdown8verU: true });
    }

    onMouseLeave8Usuario = () => {
        this.setState({ dropdown8verU: false });
    }

    onMouseEnter9 = () => {
        this.setState({ dropdown9ver: true });
    }

    onMouseLeave9 = () => {
        this.setState({ dropdown9ver: false });
    }

    onMouseEnter10Productos = () => {
        this.setState({ dropdown10verProd: true });
    }

    onMouseLeave10Productos = () => {
        this.setState({ dropdown10verProd: false });
    }

    onMouseEnter11Productos = () => {
        this.setState({ dropdown11verProd: true });
    }

    onMouseLeave11Productos = () => {
        this.setState({ dropdown11verProd: false });
    }

    onMouseEnterRanchos = () => {
        this.setState({ dropdownranchosver: true });
    }

    onMouseLeaveRanchos = () => {
        this.setState({ dropdownranchosver: false });
    }



    verMenuMovil = () => {
        this.setState({ vercollagemovil: !this.state.vercollagemovil });
    }

    desplegarMenu = (bandera, pos) => {
        if ((pos + 1) == this.state.arreglotitulos.length) {
            this.setState({ salir: true })
            return;
        }
        if (bandera == true) {
            var arraytemp = this.state.arreglotitulos;
            arraytemp[pos].vertienesubmenu = !arraytemp[pos].vertienesubmenu;
            this.setState({ arreglotitulos: arraytemp })
        }
    }

    desplegarSubMenu = (bandera, pos1, pos2) => {
        if (bandera == true) {
            var arraytemp = this.state.arreglotitulos;
            arraytemp[pos1].submenu[pos2].subvertienesubmenu = !arraytemp[pos1].submenu[pos2].subvertienesubmenu;
            this.setState({ arreglotitulos: arraytemp })
        }
    }

    desplegarSubNivel = (pos1, pos2, pos3) => {
        //ruta
    }

    verModalNotificaciones = () => {
        this.setState({ mostrarnotificaciones: true })
        //console.log("mostrar notificación")
    }

    cerrarModalNotificaciones = () => {
        this.setState({ mostrarnotificaciones: false })
        //console.log("cerrar notificación")
    }

    salir = () => {
        this.setState({ salir: true })
    }

    render() {

        let stiledropbox = {
            backgroundColor: 'transparent', color: 'black', border: 'none',
            paddingBottom: '0px', paddingTop: '0px', paddingRight: '5px', paddingLeft: '5px'
        }

        let subdivstyle = {
            backgroundColor: 'transparent', textDecoration: 'none', color: 'black', border: 'none',
            paddingRight: '8px', paddingBottom: '0px', paddingTop: '0px', paddingLeft: '0px', width: '100%', textAlign: 'left'
        }

        return (

            <>
                {this.state.navegar ?
                    <>
                        <Redirect to={this.state.rutaseleccionada} />
                    </>
                    : null}
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                    : null}

                {this.state.mostrarnotificaciones ? (
                    <ModalNotificaciones
                        titulomodal={"Tus notificaciones"} cerrarModal={this.cerrarModalNotificaciones}
                        activarSpinner={this.activarSpinner}
                        show={this.state.mostrarnotificaciones} />
                ) : null}

                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid anchoMaximo">
                        <img src="/assets/pyrgoslogodash.png" alt="imglogoprincipal" height="35" className="d-inline-block align-text-top" />

                        <div className='d-flex'>
                            <div className='navbar-toggler iconNotificationMovil' onClick={this.verModalNotificaciones} >
                                <IoMdNotifications className='icoNotifications' />
                            </div>
                            <button className="navbar-toggler" type="button" onClick={this.verMenuMovil}>
                                <AiOutlineMenu className='icoMenu' />
                            </button>
                        </div>



                        <div className="navbar-collapse collapse" >

                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-end">


                                {/*almacenes? */}
                                <li className={this.state.dropdownalmacenes ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdownalmacenes} toggle={this.verMenuAlmacenes}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[3].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[3].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>

                                {/*compras? */}
                                <li className={this.state.dropdowncompras ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdowncompras} toggle={this.verMenuComrpas}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[4].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[4].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>


                                {/* cosechas */}
                                <li className={this.state.dropdowncosechas ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdowncosechas} toggle={this.verMenuCosechas}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[6].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[6].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>

                                {/*ventas*/}
                                <li className={this.state.dropdown1ver ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdown1ver} toggle={this.verMenu}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[2].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[2].submenu.map((item, index) => (
                                                index == 10 ? (
                                                    <DropdownItem key={index}>
                                                        <Dropdown direction="right" onMouseOver={this.onMouseEnter9} onMouseLeave={this.onMouseLeave9}
                                                            isOpen={this.state.dropdown9ver} toggle={this.verSubMenuGestion9}>
                                                            <DropdownToggle style={subdivstyle}>
                                                                <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {item.subnivel.map((subitem, subindex) => (
                                                                    <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                        onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </DropdownItem>
                                                ) : (

                                                    <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                        onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                        {item.subtitulo}</DropdownItem>
                                                )

                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>





                                {/*Nuevo nomina? */}
                                <li className={this.state.dropdown10vernomina ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdown10vernomina} toggle={this.verMenuNomina}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[8].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[8].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>


                                {/*operaciones? */}
                                <li className={this.state.dropdownoperaciones ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdownoperaciones} toggle={this.verMenuOperaciones}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[5].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[5].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>


                                {/*presupuesto*/}
                                <li className="navItem">
                                    <a className='lblNavItem styleNavItem letraNavBar'
                                        onClick={() => this.setState({ navegar: true, rutaseleccionada: this.state.arreglotitulos[7].ruta })}>{this.state.arreglotitulos[7].titulo}</a>
                                </li>


                                {/*Nuevo reportes? */}
                                <li className={this.state.dropdown10verreportes ? ("navItem estiloSeleccionado") : "navItem"}>

                                    <Dropdown isOpen={this.state.dropdown10verreportes} toggle={this.verMenuReportes}>
                                        <DropdownToggle caret
                                            style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[9].titulo}</b></DropdownToggle>
                                        <DropdownMenu>

                                            {this.state.arreglotitulos[9].submenu.map((item, index) => (
                                                <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                    {item.subtitulo}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>



                                {/*gestión*/}
                                <li className="navItem">
                                    <li className={this.state.dropdown5ver ? ("navItem estiloSeleccionado") : "navItem"}>

                                        <Dropdown isOpen={this.state.dropdown5ver} toggle={this.verMenuGestios}>
                                            <DropdownToggle caret
                                                style={stiledropbox}><b className='lblNavItem letraNavBar'>{this.state.arreglotitulos[10].titulo} </b></DropdownToggle>
                                            <DropdownMenu>

                                                {this.state.arreglotitulos[10].submenu.map((item, index) => (
                                                    index == 2  ? (

                                                        <DropdownItem key={index}>
                                                            <Dropdown direction="right" onMouseOver={this.onMouseEnter6} onMouseLeave={this.onMouseLeave6}
                                                                isOpen={this.state.dropdown6ver} toggle={this.verSubMenuGestion0}>
                                                                <DropdownToggle style={subdivstyle}>
                                                                    <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {item.subnivel.map((subitem, subindex) => (
                                                                        <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                            onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </DropdownItem>

                                                    ) :
                                                        index == 3 ? (
                                                            <DropdownItem key={index}>

                                                                <Dropdown direction="right" onMouseOver={this.onMouseEnter7} onMouseLeave={this.onMouseLeave7}
                                                                    isOpen={this.state.dropdown7ver} toggle={this.verSubMenuGestion1}>
                                                                    <DropdownToggle style={subdivstyle}>
                                                                        <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {item.subnivel.map((subitem, subindex) => (
                                                                            <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>

                                                            </DropdownItem>
                                                        ) :
                                                            index == 4 ? (
                                                                <DropdownItem key={index}>

                                                                    <Dropdown direction="right" onMouseOver={this.onMouseEnter8} onMouseLeave={this.onMouseLeave8}
                                                                        isOpen={this.state.dropdown8ver} toggle={this.verSubMenuGestion2}>
                                                                        <DropdownToggle style={subdivstyle}>
                                                                            <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {item.subnivel.map((subitem, subindex) => (
                                                                                <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                    onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                            ))}
                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                </DropdownItem>
                                                            ) :

                                                                index == 7 ? (

                                                                    <DropdownItem key={index}>
                                                                        <Dropdown direction="right" onMouseOver={this.onMouseEnterRanchos} onMouseLeave={this.onMouseLeaveRanchos}
                                                                            isOpen={this.state.dropdownranchosver} toggle={this.verSubMenuGestion0}>
                                                                            <DropdownToggle style={subdivstyle}>
                                                                                <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {item.subnivel.map((subitem, subindex) => (
                                                                                    <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                        onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                                ))}
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </DropdownItem>

                                                                ) :




                                                                index == 8 ? (
                                                                    <DropdownItem key={index}>

                                                                        <Dropdown direction="right" onMouseOver={this.onMouseEnter8Usuario} onMouseLeave={this.onMouseLeave8Usuario}
                                                                            isOpen={this.state.dropdown8verU} toggle={this.verSubMenuGestion2Usuario}>
                                                                            <DropdownToggle style={subdivstyle}>
                                                                                <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {item.subnivel.map((subitem, subindex) => (
                                                                                    <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                        onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                                ))}
                                                                            </DropdownMenu>
                                                                        </Dropdown>

                                                                    </DropdownItem>
                                                                ) :
                                                                    index == 5 ? (
                                                                        <DropdownItem key={index}>

                                                                            <Dropdown direction="right" onMouseOver={this.onMouseEnter10Productos} onMouseLeave={this.onMouseLeave10Productos}
                                                                                isOpen={this.state.dropdown10verProd} toggle={this.verSubMenuGestion2Producto}>
                                                                                <DropdownToggle style={subdivstyle}>
                                                                                    <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    {item.subnivel.map((subitem, subindex) => (
                                                                                        <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                            onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                                    ))}
                                                                                </DropdownMenu>
                                                                            </Dropdown>

                                                                        </DropdownItem>
                                                                    ) :
                                                                    index == 9 ? (
                                                                        <DropdownItem key={index}>

                                                                            <Dropdown direction="right" onMouseOver={this.onMouseEnter11Productos} onMouseLeave={this.onMouseLeave11Productos}
                                                                                isOpen={this.state.dropdown11verProd} toggle={this.verSubMenuGestion2Producto}>
                                                                                <DropdownToggle style={subdivstyle}>
                                                                                    <b className='lblNavItem letraNavBar'>{item.subtitulo}</b><IoIosArrowForward className='icoArrowNavbar' />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    {item.subnivel.map((subitem, subindex) => (
                                                                                        <DropdownItem key={subindex} className='lblNavItem letraNavBar'
                                                                                            onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })}>{subitem.titulo}</DropdownItem>
                                                                                    ))}
                                                                                </DropdownMenu>
                                                                            </Dropdown>

                                                                        </DropdownItem>
                                                                    ):
                                                                        <DropdownItem key={index} className='lblNavItem letraNavBar'
                                                                            onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                                            {item.subtitulo}</DropdownItem>
                                                    //<DropdownItem key={index} className='lblNavItem letraNavBar'>{item.subtitulo}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                </li>








                                {/* perfil */}
                                <li className="navSalir d-flex">
                                    <Dropdown isOpen={this.state.dropdown4ver} toggle={this.verSalir}>
                                        <DropdownToggle
                                            style={stiledropbox}><FaUserAlt /></DropdownToggle>
                                        <DropdownMenu>

                                            <DropdownItem className='lblNavItem letraNavBar'
                                                onClick={() => this.setState({ navegar: true, rutaseleccionada: this.state.arreglotitulos[12].ruta })}>
                                                {this.state.arreglotitulos[12].titulo}</DropdownItem>

                                            <DropdownItem className='lblNavItem letraNavBar'
                                                onClick={this.salir}>
                                                {"Cerrar Sesión"}</DropdownItem>

                                        </DropdownMenu>
                                    </Dropdown>
                                    {/*<div onClick={this.verModalNotificaciones} >
                                        <IoMdNotifications className='icoNotifications' />
                                    </div>*/}
                                </li>

                            </ul>

                        </div>
                    </div>

                    <Collapse isOpen={this.state.vercollagemovil} style={{ width: '100%' }} >

                        <div className='DivMenu'>
                            {this.state.arreglotitulos.map((item, index) => (
                                (index > 1) ? (
                                    <div key={index} className="divSubMenu">

                                        {item.tienesubmenu == true ? (
                                            <div className='divContieneTitulos'
                                                onClick={this.desplegarMenu.bind(this, item.tienesubmenu, index)}>
                                                <a className="lblSubMenuItems" >{item.titulo}</a>
                                            </div>
                                        ) :
                                            (item.ruta == "cerrar") ? <div className='divContieneTitulos' onClick={this.salir}>
                                                <a className="lblSubMenuItems" >{item.titulo}</a>
                                            </div> : <div className='divContieneTitulos'
                                                onClick={() => this.setState({ navegar: true, rutaseleccionada: item.ruta })}>
                                                <a className="lblSubMenuItems" >{item.titulo}</a>
                                            </div>
                                        }


                                        {item.tienesubmenu == true ? (
                                            <Collapse isOpen={item.vertienesubmenu}>
                                                {item.submenu.map((subitem, index2) => (
                                                    <div key={index2} className="divConSubMenu">
                                                        {subitem.subtienesubmenu == true ? (
                                                            <div className='divSubContieneTitulos' onClick={this.desplegarSubMenu.bind(this, subitem.subtienesubmenu, index, index2)} >
                                                                <a className="lblConSubMenuItems" >{subitem.subtitulo}</a>
                                                            </div>
                                                        ) : (
                                                            <div className='divSubContieneTitulos' onClick={() => this.setState({ navegar: true, rutaseleccionada: subitem.ruta })} >
                                                                <a className="lblConSubMenuItems" >{subitem.subtitulo}</a>
                                                            </div>
                                                        )}



                                                        {subitem.subtienesubmenu == true ? (
                                                            <Collapse isOpen={subitem.subvertienesubmenu}>
                                                                {subitem.subnivel.map((subnivel, index3) => (
                                                                    <div key={index3} className="divSubMenu">
                                                                        <div className='divContieneTitulos' onClick={() => this.setState({ navegar: true, rutaseleccionada: subnivel.ruta })} >
                                                                            <a className="lblSubMenuItems"  >{subnivel.titulo}</a>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Collapse>) : null}
                                                    </div>
                                                ))}
                                            </Collapse>
                                        ) : null}
                                    </div>
                                ) : null

                            ))}
                        </div>

                    </Collapse>
                </nav>

            </>

        );
    }
}

function Acciones({ bandera }) {
    const history = useHistory();
    const location = useLocation();

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

}



