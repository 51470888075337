import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './OrdenTrabajo.css';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnNuevo, estiloBtnVer, estiloColorError,
  estiloComboBox, estiloFiltroEncabezados
} from '../../Services/PaletaDeColores';
//import Zona from './Zona';
//import Stock from './Stock'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { BsFillEyeFill, BsNutFill } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi'
import { FaCalendar, FaTrashAlt } from 'react-icons/fa'


import {
  getRequisicionInformacion, getEstatus, getListaZonas, getListaRanchosDisponibles,
  getListaActividades, getListaTipoActividades, // Modal Seleccionar actividades
  getListaTablaCultivos, getListaLotes, //Modal seleccionar tablas
  getListaMaquinarias, getCatalogoMaquinarias, getListaTipoMaquinarias, //Modal seleccionar maquinaria
  getListaEquipos, getListaTipoEquipos,// Modal seleccionar equipo
  getListaCombustibles, guardarOrdenTrabajo, informacionOrdenTrabajo, getEstatusOrdenTrabajo, reactivarOrdenTrabajo
} from '../../Services/Api';
import { validarNumeros, validarDescripciónOrdenTrabajo } from '../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { asignacionTablaFuncion, getTablaMaquinaria, getTablaEquipo, getTablaProductos } from './DatosDummy'

import ModalDocumentos from '../../Complements/Modales/ModalRequisicion/ModalDocumentos';
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatusContrasena';

//import ModalBuscarProducto from '../../Complements/Modales/ModalOrdenTrabajo/ModalBuscarProducto';
import ModalAgregarProductos from '../../Complements/Modales/ModalOrdenTrabajo/ModalAgregarProductos'
import ModalSeleccionActividades from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionActividades'
import ModalSeleccionTablas from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionTablas'
import ModalSeleccionMaquinaria from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionMaquinaria'
import ModalSeleccionEquipo from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionEquipo'

import ModalSeleccionAnio from '../../Complements/Modales/ModalFormularioPlanAnual/ModalSeleccionAnio'
import ModalSeleccionMeses from '../../Complements/Modales/ModalOrdenTrabajo/MesesAnio/ModalSeleccionMeses'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import ModalAgregarAgroquimicos from '../../Complements/Modales/ModalOrdenTrabajo/ModalAgregarAgroquimicos';
import ModalNotasOrden from '../../Complements/Modales/ModalOrdenTrabajo/ModalNotasOrden';
registerLocale('es', es)

const todoslosfiltrosdisponibles = -1

export default class OrdenTrabajo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      idorden: 0,
      zona: "",
      rancho: "",

      // variables para la sección 1
      plananual: false,
      anio: new Date(),
      mes: new Date(), //pendiente de tomar del selector

      idzonaseleccionada: todoslosfiltrosdisponibles,
      msgerroridzona: '',
      vermsgerroridzona: false,

      idranchoseleccionado: todoslosfiltrosdisponibles,
      msgerroridrancho: '',
      vermsgerroridrancho: false,

      actividadseleccionada: '',
      msgerroractividad: '',
      vermsgerroractividad: false,

      fechaprincipal: new Date(),
      txtfechasugerida: '',
      vermsgerrorfecha: false,
      msgerrorfecha: '',

      duracionhoras: '',
      vermsgerrorduracionhoras: false,
      msgerrorduracionhoras: '',

      numeroparticipantes: '',
      vermsgerrornumeroparticipantes: false,
      msgerrornumeroparticipantes: '',

      actividadoperativa: false,

      txtdescripcion: '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: '',

      estatuscambiado: false,

      // Sección listas de selectores
      listanotas: [],
      listaestatusorden: [],
      listazonas: [],
      listaranchos: [],
      listacombustibles: [],
      listaactividades: [],
      listatipoactividades: [],
      listatablascultivo: [],
      listalotes: [],
      listamaquinaria: [],
      listatipomaquinaria: [],
      listaequipos: [],
      listatipoequipos: [],
      listaestatus: [],

      // Radio buttons
      requieremaquinaria: false,
      requiereequipo: false,
      requiereproductos: false,
      requiereagroquimicos: false,

      //activadores modales
      modalactividades: false,
      modaltablas: false,
      modalmaquinaria: false,
      modalequipo: false,
      modalproductos: false,
      modalagroquimicos: false,
      modalseleccionanio: false,

      // Sección tablas de información en vista principal
      lote: { idlote: 0, lote: "" },
      tablaasignartablas: {
        encabezados: [
          "Tabla",
          "Estatus de siembra",
          "Área total (ha)",
          "Plantas totales(ud)",
          ""
        ],
        dimensiones: [
          "27.5%",
          "27.5%",
          "20%",
          "20%",
          "5%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px",
          "200px",
          "50px",
        ],
        elementos: []
      },
      tablamaquinaria: {
        encabezados: [
          "Maquinaria",
          "Tipo maquinaria",
          ""
        ],
        dimensiones: [
          "46%",
          "24%",
          "5%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "50px",
        ],
        elementos: []
      },
      tablaequipos: {
        encabezados: [
          "Equipo",
          "Tipo equipo",
          ""
        ],
        dimensiones: [
          "46%",
          "24%",
          "5%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "50px",
        ],
        elementos: []
      },
      tablaproductos: {
        encabezados: [

          "Nombre",
          "Unidad de medida",
          "Cantidad",
          ""
        ],
        dimensiones: [

          "43%",
          "19%",
          "15%",
          "8%",
        ],
        mindimensiones: [

          "290px",
          "200px",
          "180px",
          "50px",
        ],
        elementos: []
      },
      tablaagroquimicos: {
        encabezados: [

          "Nombre",
          "Unidad de medida",
          "Cantidad",
          ""
        ],
        dimensiones: [

          "43%",
          "19%",
          "15%",
          "8%",
        ],
        mindimensiones: [

          "290px",
          "200px",
          "180px",
          "50px",
        ],
        elementos: []
      },



      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      anioseleccionado: new Date().getFullYear(),
      modalmeses: false,
      messeleccionado: { id_mes: -1, mes: '----' },
      idestatusordenseleccionado: 1,

      redireccionando: false,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,


      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colormsgerror: estiloColorError().msgerror,



    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true, estatuscambiado: false, })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    //var idrequisicion = await localStorage.getItem('datoorden')
    var datoorden = localStorage.getItem('datoorden')


    if (datoorden != null) {
      const { orden, opcion } = JSON.parse(datoorden)

      // Sección para solicitar datos de las listas (selectores)
      var resplistazonas = await getListaZonas();
      if (this.manejadorLlamadasApis(resplistazonas)) {
        return;
      }
      var listazonas = JSON.parse(JSON.stringify(resplistazonas.data));

      var resplistaranchos = await getListaRanchosDisponibles()
      if (this.manejadorLlamadasApis(resplistaranchos)) {
        return;
      }
      var listaranchos = JSON.parse(JSON.stringify(resplistaranchos.data));
      this.setState({ listazonas: listazonas, listaranchos: listaranchos })

      // datos para lista de combustibles
      var resplistacombustibles = await getListaCombustibles();
      if (this.manejadorLlamadasApis(resplistacombustibles)) {
        return;
      }
      this.setState({ listacombustibles: this.llenarListaCombustibles(resplistacombustibles.data) })

      // Datos para modal "Seleccionar actividades"
      var resplistaactividades = await getListaActividades()
      if (this.manejadorLlamadasApis(resplistaactividades)) {
        return;
      }
      var listaactividades = resplistaactividades.data

      var resplistatipoactividades = await getListaTipoActividades()
      if (this.manejadorLlamadasApis(resplistatipoactividades)) {
        return;
      }
      var listatipoactividades = resplistatipoactividades.data
      this.setState({ listatipoactividades: listatipoactividades, listaactividades: listaactividades })

      // Datos para modal "Seleccionar tablas"
      var resplistatablas = await getListaTablaCultivos()
      if (this.manejadorLlamadasApis(resplistatablas)) {
        return;
      }
      var listatablascultivo = resplistatablas.data

      var resplistalotes = await getListaLotes()
      if (this.manejadorLlamadasApis(resplistalotes)) {
        return;
      }
      var listalotes = resplistalotes.data
      this.setState({ listatablascultivo: listatablascultivo, listalotes: listalotes })

      // Datos para modal "Seleccionar maquinaria"
      var resplistamaquinaria = await getListaMaquinarias()
      if (this.manejadorLlamadasApis(resplistamaquinaria)) {
        return;
      }
      var listamaquinaria = resplistamaquinaria.data

      var resplistalotes = await getListaTipoMaquinarias()
      if (this.manejadorLlamadasApis(resplistalotes)) {
        return;
      }
      var listatipomaquinaria = resplistalotes.data
      this.setState({ listamaquinaria: listamaquinaria, listatipomaquinaria: listatipomaquinaria })

      // Datos para modal "Seleccionar equipos"
      var resplistaequipos = await getListaEquipos()
      if (this.manejadorLlamadasApis(resplistaequipos)) {
        return;
      }
      var listaequipos = resplistaequipos.data

      var resplistatipoequipos = await getListaTipoEquipos()
      if (this.manejadorLlamadasApis(resplistatipoequipos)) {
        return;
      }
      var listatipoequipos = resplistatipoequipos.data


      this.setState({ listaequipos: listaequipos, listatipoequipos: listatipoequipos })


      // Datos para lista de estatus de orden 

      var resplistaestatus = await getEstatusOrdenTrabajo()
      if (this.manejadorLlamadasApis(resplistaestatus)) {
        return;
      }
      var listaestatus = resplistaestatus.data
      this.setState({ listaestatus: listaestatus })


      // Sección para información de tablas seleccionadas
      if (orden != 0) {

        let respinformacion = await informacionOrdenTrabajo({ idorden: parseInt(orden) });
        if (this.manejadorLlamadasApis(respinformacion)) {
          return;
        }

        let info = respinformacion.data;


        this.setState({
          infoorden: info,
          idorden: info.idordentrabajo,
          idzonaseleccionada: info.idzona,
          idranchoseleccionado: info.idrancho,
          zona: info.zona,
          rancho: info.rancho,
          actividadseleccionada: { idactividad: info.idactividad, actividad: info.actividad },
          numeroparticipantes: String(info.participantes_estimados),
          duracionhoras: String(info.horas_estimadas),
          txtdescripcion: info.descripcion,
          listanotas: info.notas,
          listacombustibles: this.cantidadesListaCombustibles(info.combustibles),
          idestatusordenseleccionado: parseInt(info.idestatus_orden),

          plananual: info.tieneplananual == 1 ? (true) : (false),

          anioseleccionado: info.anio != "" ? (info.anio) : (new Date().getFullYear()),
          messeleccionado: { id_mes: info.idmes, mes: info.mes },

          actividadoperativa: info.actividadoperativa == 0 ? (false) : (true),
          requieremaquinaria: info.maquinarias.length > 0 ? (true) : (false),
          requiereequipo: info.equipos.length > 0 ? (true) : (false),
          requiereproductos: info.productosgenerales.length > 0 ? (true) : (false),
          requiereagroquimicos: info.agroquimicos.length > 0 ? (true) : (false),

          lote: { idlote: info.idlote, lote: info.lote },

          tablaasignartablas: this.llenarTablasAsignadas(info.tablas),
          tablamaquinaria: this.llenarMaquinariasAsignadas(info.maquinarias),
          tablaequipos: this.llenarEquiposAsignados(info.equipos),
          tablaproductos: this.llenarProductosAsignados(info.productosgenerales),
          tablaagroquimicos: this.llenarAgroquimicosAsignados(info.agroquimicos),

        })

      }




    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  llenarListaCombustibles(combustibles) {
    let nuevalista = [];
    combustibles.forEach(comb => {

      nuevalista.push({
        "idtipocombustible": comb.idtipocombustible,
        "tipo": comb.tipo,
        "descripcion": comb.descripcion,
        "cantidad": '',
        "msgerror": "",
        "vermsgerror": false
      })
    });

    return nuevalista;
  }

  cantidadesListaCombustibles(listaorden) {
    let lista = this.state.listacombustibles;
    lista.forEach(comb => {
      let encontrado = listaorden.find(x => x.idcombustible == comb.idtipocombustible)
      if (encontrado) {
        comb.cantidad = String(encontrado.cantidad);
      }
    });
    return lista
  }

  llenarTablasAsignadas(tablasorden) {
    let lista = this.state.tablaasignartablas;
    lista.elementos = [];
    this.state.listatablascultivo.forEach(el => {
      let encontrado = tablasorden.find(x => x.idtabla == el.idcatalogo_lotecultivo_tabla)
      if (encontrado) {
        lista.elementos.push({
          areatotal: encontrado.areatotal,
          estatus_siembra: encontrado.estatus_siembra,
          idcatalogo_lotecultivo_tabla: encontrado.idtabla,
          plantastotales: encontrado.plantastotales,
          tabla: encontrado.tabla
        });
      }
    });


    return lista
  }

  llenarMaquinariasAsignadas(maquinariaorden) {
    let lista = this.state.tablamaquinaria;
    lista.elementos = [];
    this.state.listamaquinaria.forEach(el => {
      let encontrado = maquinariaorden.find(x => x.idmaquinaria == el.idmaquinaria)
      if (encontrado) {
        lista.elementos.push(el);
      }
    });


    return lista
  }

  llenarEquiposAsignados(equiposorden) {
    let lista = this.state.tablaequipos;
    lista.elementos = [];

    this.state.listaequipos.forEach(el => {
      let encontrado = equiposorden.find(x => x.idequipo == el.idequipo)
      if (encontrado) {

        lista.elementos.push({
          idequipo: el.idequipo,
          idtipo_equipo: el.idtipoequipo,
          tipoequipo: encontrado.tipoequipo,
          nombreclave: el.nombreclave
        });
      }
    });


    return lista
  }

  llenarProductosAsignados(productosorden) {
    let lista = this.state.tablaproductos;
    lista.elementos = [];

    productosorden.forEach(el => {

      lista.elementos.push(
        {
          "clave": el.clave,
          "claveunidadmedida": el.unidadmedida,
          "cantidad": el.candidad,
          "fondo": "",
          "idcatalogo_unidadmedida": "",
          "idcatalogoalmacengeneral": "",
          "idcatalogo_producto_general": el.idproducto,
          "idproducto_inventariogeneral": "",
          "idtipoproducto": "",
          "nombrecomercial": el.producto,
          "tipoproducto": "",
          "unidadmedida": ""
        }
      );

    });


    return lista
  }




  llenarAgroquimicosAsignados(agroquimicosorden) {
    let lista = this.state.tablaagroquimicos;
    lista.elementos = [];

    agroquimicosorden.forEach(el => {

      lista.elementos.push(
        {
          "clave": el.clave,
          "claveunidadmedida": el.unidadmedida,
          "cantidad": el.candidad,
          "idcatalogoproducto": el.idproducto,
          "nombrecomercial": el.producto,
        }
      );

    });


    return lista
  }
  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }



  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      const aux = this.state.informacionrequisicion
      var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion, idestatusrequisicion: 3 /* id de autorizar */ }
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.accion == 1) {
      this.redireccionando()
    }
  }

  /// Sección de modales
  verModalProductos = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Agregar productos', accionmodal: 1, modalproductos: true })
    }
  }

  cerrarModalProductos = (accion) => {


    this.setState({ modalproductos: !this.state.modalproductos })
    if (accion == 1) {
      this.componentDidMount();
    }
    if (accion != 0) {
      let x = this.state.tablaproductos;
      x.elementos = accion;
      this.setState({ tablaproductos: x })
    }
  }

  verModalAgroquimicos = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Agregar agroquímico', accionmodal: 1, modalagroquimicos: true })
    }
  }

  cerrarModalAgroquimicos = (accion) => {


    this.setState({ modalagroquimicos: !this.state.modalagroquimicos })
    if (accion == 1) {
      this.componentDidMount();
    }
    if (accion != 0) {
      let x = this.state.tablaagroquimicos;
      x.elementos = accion;
      this.setState({ tablaagroquimicos: x })
    }
  }







  verModalActividades = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Selecciona una actividad', accionmodal: 1, modalactividades: true })
    }
  }
  cerrarModalActividades = (ban) => {

    this.setState({ modalactividades: !this.state.modalactividades })
    if (ban != 0) {
      var actividad = Object.values(ban)[0]

      let actividadseleccionada = actividad != undefined ? actividad : ''
      this.setState({ actividadseleccionada: actividadseleccionada })

    }
  }

  verModalTablas = (ban) => {
    if (ban.opcion == 1) { //Nuevo

      this.setState({ titulomodal: 'Selecciona tablas ' /*+ this.state.listaranchos.find(x=> x.idcatalogo_rancho==this.state.idranchoseleccionado).rancho,*/, accionmodal: 1, modaltablas: true })
    }
  }
  cerrarModalTablas = (ban) => {
    this.setState({ modaltablas: !this.state.modaltablas })
    if (ban != 0) {

      let x = this.state.tablaasignartablas;
      x.elementos = ban.tablas;
      this.setState({ tablaasignartablas: x, lote: ban.lote })
    }
  }

  verModalMaquinaria = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Selecciona maquinaria', accionmodal: 1, modalmaquinaria: true })
    }
  }
  cerrarModalMaquinaria = (ban) => {


    this.setState({ modalmaquinaria: !this.state.modalmaquinaria });
    if (ban != 0) {

      let x = this.state.tablamaquinaria;
      x.elementos = ban;
      this.setState({ tablamaquinaria: x })
    }
  }

  verModalEquipo = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Selecciona equipo', accionmodal: 1, modalequipo: true })
    }
  }
  cerrarModalEquipo = (ban) => {
    this.setState({ modalequipo: !this.state.modalequipo })
    if (ban != 0) {

      let x = this.state.tablaequipos;
      x.elementos = ban;
      this.setState({ tablaequipos: x })
    }
  }

  verModalSeleccionAnio = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Selección de año', accionmodal: 1, itemseleccionado: {} })
    }
    this.setState({ modalseleccionanio: true });
  }

  cerrarModalSeleccionAnio = ({ accion, anioseleccionado }) => {
    this.setState({ modalseleccionanio: !this.state.modalseleccionanio })
    if (accion == 1) {
      if (this.state.anioseleccionado != anioseleccionado) {
        this.setState({ anioseleccionado: anioseleccionado })
        //this.filtrarComboAnio(anioseleccionado)
      } else {
        this.setState({ loadingspinners: false })
      }
    }
  }

  verModalMeses = () => {
    this.setState({ titulomodal: 'Selecciona un mes', accionmodal: 1, modalmeses: true });
  }

  cerrarModalMeses = async (mes) => {
    this.setState({ modalmeses: false })
    if (mes != 0) {
      this.setState({ messeleccionado: mes });
    }
  }

  cambiarTipo = (item) => {
    this.setState({ [item.llave]: item.valor, estatuscambiado: true })
  }

  escribir = (prop) => async (event) => {
    var formatofecha = event.target.value;
    await this.setState({ [prop]: formatofecha });
  }

  escribircombustible = (prop) => async (event) => {
    let nuevalista = this.state.listacombustibles;
    nuevalista[prop].cantidad = event.target.value
    this.setState({ listacombustibles: nuevalista })
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })
  }

  escribirTextArea = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  onChange = () => {

  }

  guardarCambios = async () => {

    let validacionescorrectas = true
    this.setState({ vermsgerroridzona: false })
    this.setState({ vermsgerroridrancho: false })
    this.setState({ vermsgerroractividad: false })
    this.setState({ vermsgerrorfecha: false })
    this.setState({ vermsgerrorduracionhoras: false })
    this.setState({ vermsgerrornumeroparticipantes: false })
    this.setState({ vermsgerrortxtdescripcion: false })
    this.setState({ listacombustibles: this.limpiarmensajescombustibles() })

    /* zona */
    if (this.state.idzonaseleccionada == todoslosfiltrosdisponibles) {
      this.setState({ msgerroridzona: "Selecciona una zona" })
      this.setState({ vermsgerroridzona: true })
      validacionescorrectas = false;
    }

    /* rancho */
    if (this.state.idranchoseleccionado == todoslosfiltrosdisponibles) {
      if (this.state.idzonaseleccionada == todoslosfiltrosdisponibles) {
        this.setState({ msgerroridrancho: "Selecciona una zona primero" })
      } else {
        this.setState({ msgerroridrancho: "Selecciona un rancho" })
      }
      this.setState({ vermsgerroridrancho: true })
      validacionescorrectas = false;
    }

    /* actividad */
    if (this.state.actividadseleccionada == '') {
      this.setState({ msgerroractividad: "Selecciona una actividad" })
      this.setState({ vermsgerroractividad: true })
      validacionescorrectas = false;
    }

    /* fecha */
    let fecha = "";
    if (this.state.fechaprincipal == '') {
      this.setState({ msgerrorfecha: "Selecciona una fecha" })
      this.setState({ vermsgerrorfecha: true })
      validacionescorrectas = false;
    } else {

      let f = this.state.fechaprincipal
      //fecha = f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear();
      let day = `0${f.getDate()}`.slice(-2)
      let month = `0${f.getMonth() + 1}`.slice(-2)
      let year = f.getFullYear()
      fecha = day + '/' + month + '/' + year

      this.setState({ txtfechasugerida: fecha })
    }

    /* año de plan nual */
    if (this.state.anioseleccionado == '' && this.state.plananual == true) {
      alert("no hay año seleccionado ")
    }
    /* mes de plan anual*/
    if (this.state.messeleccionado.id_mes == -1 && this.state.plananual == true) {
      this.setState({
        msgerrormesseleccionado: "Selecciona un mes",
        vermsgerrormesseleccionado: true
      });
    }

    /* duración */
    let longitudvalida = { longitudminima: 1, longitudmaxima: 2 };
    let duracionhoras = validarNumeros(this.state.duracionhoras.trim(), longitudvalida)
    if (duracionhoras.codigo == "invalid") {
      this.setState({ msgerrorduracionhoras: duracionhoras.mensaje })
      this.setState({ vermsgerrorduracionhoras: true })
      validacionescorrectas = false;
    }

    /* participantes */
    longitudvalida = { longitudminima: 1, longitudmaxima: 4 };
    let numeroparticipantes = validarNumeros(this.state.numeroparticipantes.trim(), longitudvalida)
    if (numeroparticipantes.codigo == "invalid") {
      this.setState({ msgerrornumeroparticipantes: numeroparticipantes.mensaje })
      this.setState({ vermsgerrornumeroparticipantes: true })
      validacionescorrectas = false;
    }

    /* combustibles */
    longitudvalida = { longitudminima: 1, longitudmaxima: 6 };
    let listacombustibles = this.state.listacombustibles;
    listacombustibles.forEach(comb => {

      if (comb.cantidad == '') {

      }
      else {
        let resultado = validarNumeros(comb.cantidad.trim(), longitudvalida)
        if (resultado.codigo == "invalid") {

          comb.vermsgerror = true;
          comb.msgerror = resultado.mensaje;
          validacionescorrectas = false;
        }
      }
    });


    /* plan anual */
    /* actividad operativa */

    /* descripción */
    longitudvalida = { longitudminima: (parseInt(this.state.idorden)==0)?0:1, longitudmaxima: 1000 };
    let txtdescripcion = validarDescripciónOrdenTrabajo(this.state.txtdescripcion.trim(), longitudvalida)
    if (txtdescripcion.codigo == "invalid") {
      this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
      this.setState({ vermsgerrortxtdescripcion: true })
      validacionescorrectas = false;
    }

    /* tablas */

    if (this.state.actividadoperativa == false && this.state.tablaasignartablas.elementos.length == 0) {
      toast.error("Esta orden requiere al menos una tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      validacionescorrectas = false;
    }

    /* maquinaria puede ir vacía*/

    if (this.state.requieremaquinaria == true) {
      if (this.state.tablamaquinaria.elementos.length == 0) {
        toast.error("Agrega al menos una maquinaria o vehículo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        validacionescorrectas = false;
      }
    }


    /* equipo puede ir vacío*/
    if (this.state.requiereequipo == true) {
      if (this.state.tablaequipos.elementos.length == 0) {
        toast.error("Agrega al menos un equipo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        validacionescorrectas = false;
      }
    }
    /* productos puede ir vacío*/
    if (this.state.requiereproductos == true) {
      if (this.state.tablaproductos.elementos.length == 0) {
        toast.error("Agrega al menos un producto general", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        validacionescorrectas = false;
      }
    }

    /* agroquímicos puede ir vacío*/









    if (validacionescorrectas) {

      let tablasseleccionadas = [];
      let maquinariasseleccionadas = [];
      let equiposseleccionados = [];
      let productosgeneralesseleccionados = [];
      let agroquimicosseleccionados = [];
      let listanotas = [];

      let combustibles = [];

      this.state.tablamaquinaria.elementos.forEach(el => {

        let tempidordenmaquinaria = 0;
        if (parseInt(this.state.idorden) != 0) {
          let existentes = this.state.infoorden.maquinarias;
          let encontrado = existentes.find(x => parseInt(x.idmaquinaria) == parseInt(el.idmaquinaria));
          tempidordenmaquinaria = encontrado != undefined ? (parseInt(encontrado.idordenmaquinaria)) : 0
        }


        maquinariasseleccionadas.push({
          "idordenmaquinaria": parseInt(tempidordenmaquinaria),
          "idmaquinaria": parseInt(el.idmaquinaria),
          "idtipomaquinaria": parseInt(el.idtipomaquinaria)
        })
      });

      this.state.tablaequipos.elementos.forEach(el => {

        let tempidordenequipo = 0;
        if (this.state.idorden != 0) {
          let existentes = this.state.infoorden.equipos;
          let encontrado = existentes.find(x => parseInt(x.idequipo) == parseInt(el.idequipo));
          tempidordenequipo = encontrado != undefined ? (parseInt(encontrado.idordenequipo)) : 0
        }

        equiposseleccionados.push({
          "idordenequipo": parseInt(tempidordenequipo),
          "idequipo": parseInt(el.idequipo),
          "idtipoequipo": parseInt(el.idtipo_equipo)
        })
      });

      this.state.tablaproductos.elementos.forEach(el => {

        productosgeneralesseleccionados.push({
          "idproducto": parseInt(el.idcatalogo_producto_general),
          "cantidad": parseInt(el.cantidad)
        })
      });

      this.state.tablaagroquimicos.elementos.forEach(el => {
        agroquimicosseleccionados.push({
          "idproducto": parseInt(el.idcatalogoproducto),
          "cantidad": parseInt(el.cantidad)
        })
      });

      this.state.tablaasignartablas.elementos.forEach(el => {
        tablasseleccionadas.push({
          idtabla: parseInt(el.idcatalogo_lotecultivo_tabla),
        })
      });


      this.state.listacombustibles.forEach(el => {
        combustibles.push({
          idcombustible: parseInt(el.idtipocombustible),
          cantidad: parseInt(el.cantidad)
        })
      });


      this.state.listanotas.forEach(el => {
        listanotas.push({
          idnota: parseInt(el.inota) > 0 ? (parseInt(el.idnota)) : (0),
          titulo: el.titulo,
          descripcion: el.descripcion
        })
      });


      this.setState({ loadingspinners: true })
      var resp = {};
      var json = {};
      json = {
        "idordentrabajo": parseInt(this.state.idorden),
        "tieneplananual": this.state.plananual == false ? (0) : 1,
        "idrancho": parseInt(this.state.idranchoseleccionado),
        "anio": this.state.plananual == true ? (String(this.state.anioseleccionado)) : "",
        "idactividad": parseInt(this.state.actividadseleccionada.idactividad),
        "idmes": this.state.plananual == true ? (parseInt(this.state.messeleccionado.id_mes)) : 0,
        "fecha_estimada": this.state.idorden == 0 ? (fecha) : this.state.infoorden.fechaestimada,
        "horas_estimadas": this.state.duracionhoras,
        "participantes_estimados": this.state.numeroparticipantes,
        "combustibles": combustibles,
        "actividadoperativa": this.state.actividadoperativa == false ? (0) : (1),
        "idestatus_orden": parseInt(this.state.idestatusordenseleccionado),
        "notas": listanotas,//this.state.listanotas,
        "descripcion": this.state.txtdescripcion,
        "tablas": (this.state.actividadoperativa == false ? (tablasseleccionadas) : (this.state.requieretablas == true ? (tablasseleccionadas) : [])),
        "maquinarias": this.state.requieremaquinaria == true ? maquinariasseleccionadas : [],
        "equipos": this.state.requiereequipo == true ? equiposseleccionados : [],
        "productosgenerales": this.state.requiereproductos == true ? productosgeneralesseleccionados : [],
        "agroquimicos": this.state.requiereagroquimicos == true ? agroquimicosseleccionados : []
      }

      // console.log("json guardar", json)
      resp = await guardarOrdenTrabajo(json);
      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setState({ loadingspinners: false })
        return true
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true
      }
      else {
        // this.showModal({ accion: 1, id: this.state.accion })
        toast.success("Orden guardada exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });

        if ("mensaje" in resp.data) {
          let listapendientes = this.listarElementos(resp.data.mensaje)
          toast.warn(
            <label style={{ fontSize: "12px" }}>
              <p>Abastecimiento insuficiente:</p>
              {listapendientes.map((item, index) => (
                <>
                  <p style={{ margin: "0px", fontWeight: "bold" }}>{item.titulo}: </p>
                  <p style={{ margin: "0px" }}>Disponibles: {item.disponibles} - Solicitados: {item.solicitados}</p>
                </>

              ))}

            </label>,
            { autoClose: false, position: toast.POSITION.TOP_LEFT, closeButton: true })

        }
        this.setState({ estatuscambiado: false, })
        localStorage.setItem('datoorden', JSON.stringify({ "orden": resp.data.idordentrabajo, "opcion": false }))
        this.componentDidMount();
      }



    }
  }

  listarElementos(lista) {
    let listaitems = []

    if ("combustibles" in lista) {
      lista.combustibles.forEach(item => {
        listaitems.push({ titulo: item.nombre, disponibles: item.disponible, solicitados: item.solicitada })
      });
    }
    if ("generales" in lista) {
      lista.generales.forEach(item => {
        listaitems.push({ titulo: item.nombre, disponibles: item.disponible, solicitados: item.solicitada })
      });
    }
    if ("agroquímicos" in lista) {
      lista.agroquímicos.forEach(item => {
        listaitems.push({ titulo: item.nombre, disponibles: item.disponible, solicitados: item.solicitada })
      });
    }

    return listaitems;
  }

  limpiarmensajescombustibles() {

    let listamensajes = this.state.listacombustibles;

    listamensajes.forEach(el => {
      el.vermsjerror = false;
      el.msgerror = "";
    });

    return listamensajes
  }


  seleccionarElementoaEliminar(tabla, indexitem) {
    let lista = [];

    this.state[tabla].elementos.forEach((el, index) => {
      if (index != indexitem) {
        lista.push(el)
      }
    });
    let x = this.state[tabla]
    x.elementos = lista
    this.setState({ [tabla]: x })
    if (tabla == "tablaasignartablas" && lista.length == 0) {
      this.setState({ lote: { idlote: 0, lote: "" } })
    }
  }




  cancelarFormulario = async () => {
    this.setState({ loadingspinners: true })
    if (this.state.idorden == 0) {

      this.setState({
        salir: false,
        altura: window.innerHeight,
        anchura: window.innerWidth,
        loadingspinners: true,
        errordeacceso: false,

        estatus: [],

        accionmodal: 1,
        titulomodal: '',
        modaldetalles: false,
        itemseleccionado: null,
        idorden: 0,
        zona: "",
        rancho: "",

        // variables para la sección 1
        plananual: false,
        anio: new Date(),
        mes: new Date(), //pendiente de tomar del selector

        idzonaseleccionada: todoslosfiltrosdisponibles,
        msgerroridzona: '',
        vermsgerroridzona: false,

        idranchoseleccionado: todoslosfiltrosdisponibles,
        msgerroridrancho: '',
        vermsgerroridrancho: false,

        actividadseleccionada: '',
        msgerroractividad: '',
        vermsgerroractividad: false,

        fechaprincipal: new Date(),
        txtfechasugerida: '',
        vermsgerrorfecha: false,
        msgerrorfecha: '',

        duracionhoras: '',
        vermsgerrorduracionhoras: false,
        msgerrorduracionhoras: '',

        numeroparticipantes: '',
        vermsgerrornumeroparticipantes: false,
        msgerrornumeroparticipantes: '',

        actividadoperativa: false,

        txtdescripcion: '',
        vermsgerrortxtdescripcion: false,
        msgerrortxtdescripcion: '',



        // Sección listas de selectores
        listanotas: [],
        listaestatusorden: [],
        listazonas: [],
        listaranchos: [],
        listacombustibles: [],
        listaactividades: [],
        listatipoactividades: [],
        listatablascultivo: [],
        listalotes: [],
        listamaquinaria: [],
        listatipomaquinaria: [],
        listaequipos: [],
        listatipoequipos: [],
        listaestatus: [],

        // Radio buttons
        requieremaquinaria: false,
        requiereequipo: false,
        requiereproductos: false,
        requiereagroquimicos: false,

        //activadores modales
        modalactividades: false,
        modaltablas: false,
        modalmaquinaria: false,
        modalequipo: false,
        modalproductos: false,
        modalagroquimicos: false,
        modalseleccionanio: false,

        // Sección tablas de información en vista principal
        lote: { idlote: 0, lote: "" },
        tablaasignartablas: {
          encabezados: [
            "Tabla",
            "Estatus de siembra",
            "Área total (ha)",
            "Plantas totales(ud)",
            ""
          ],
          dimensiones: [
            "27.5%",
            "27.5%",
            "20%",
            "20%",
            "5%",
          ],
          mindimensiones: [
            "250px",
            "250px",
            "200px",
            "200px",
            "50px",
          ],
          elementos: []
        },
        tablamaquinaria: {
          encabezados: [
            "Maquinaria",
            "Tipo maquinaria",
            ""
          ],
          dimensiones: [
            "46%",
            "24%",
            "5%",
          ],
          mindimensiones: [
            "250px",
            "250px",
            "50px",
          ],
          elementos: []
        },
        tablaequipos: {
          encabezados: [
            "Equipo",
            "Tipo equipo",
            ""
          ],
          dimensiones: [
            "46%",
            "24%",
            "5%",
          ],
          mindimensiones: [
            "250px",
            "250px",
            "50px",
          ],
          elementos: []
        },
        tablaproductos: {
          encabezados: [
            "Clave",
            "Nombre",
            "Unidad de medida",
            "Cantidad",
            ""
          ],
          dimensiones: [
            "15%",
            "43%",
            "19%",
            "15%",
            "8%",
          ],
          mindimensiones: [
            "200px",
            "290px",
            "200px",
            "180px",
            "50px",
          ],
          elementos: []
        },
        tablaagroquimicos: {
          encabezados: [
            "Clave",
            "Nombre",
            "Unidad de medida",
            "Cantidad",
            ""
          ],
          dimensiones: [
            "15%",
            "43%",
            "19%",
            "15%",
            "8%",
          ],
          mindimensiones: [
            "200px",
            "290px",
            "200px",
            "180px",
            "50px",
          ],
          elementos: []
        },



        colorverlbl: estiloBtnVer().colorverlbl,
        borderver: estiloBtnVer().borderver,

        anioseleccionado: new Date().getFullYear(),
        modalmeses: false,
        messeleccionado: { id_mes: -1, mes: '----' },
        idestatusordenseleccionado: 1,

        redireccionando: false,

        colortitulo: estiloFiltroEncabezados().colortitulos,
        colortipolbl: estiloComboBox().colorcombolbl,
        colorcbxtipolbl: estiloComboBox().colorcbxlbl,
        colorbordertipo: estiloComboBox().colorbordercombo,
        colorfondotipo: estiloComboBox().colorfondocombo,


        colorlupa: coloresTxtBuscador().colorlupa,
        fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
        fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
        colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
        bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


        fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
        colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
        borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
        colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
        fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

        colormsgerror: estiloColorError().msgerror,

      })

    }
    else {
      let info = this.state.infoorden;

      this.setState({
        infoorden: info,
        idorden: info.idordentrabajo,
        idzonaseleccionada: info.idzona,
        idranchoseleccionado: info.idrancho,
        zona: info.zona,
        rancho: info.rancho,
        actividadseleccionada: { idactividad: info.idactividad, actividad: info.actividad },
        numeroparticipantes: String(info.participantes_estimados),
        duracionhoras: String(info.horas_estimadas),
        txtdescripcion: info.descripcion,
        listanotas: info.notas,
        listacombustibles: this.cantidadesListaCombustibles(info.combustibles),
        idestatusordenseleccionado: info.idestatus_orden,

        plananual: info.tieneplananual == 1 ? (true) : (false),

        anioseleccionado: info.anio != "" ? (info.anio) : (new Date().getFullYear()),
        messeleccionado: { id_mes: info.idmes, mes: info.mes },

        actividadoperativa: info.actividadoperativa == 0 ? (false) : (true),
        requieremaquinaria: info.maquinarias.length > 0 ? (true) : (false),
        requiereequipo: info.equipos.length > 0 ? (true) : (false),
        requiereproductos: info.productosgenerales.length > 0 ? (true) : (false),
        requiereagroquimicos: info.agroquimicos.length > 0 ? (true) : (false),

        lote: { idlote: info.idlote, lote: info.lote },

        tablaasignartablas: this.llenarTablasAsignadas(info.tablas),
        tablamaquinaria: this.llenarMaquinariasAsignadas(info.maquinarias),
        tablaequipos: this.llenarEquiposAsignados(info.equipos),
        tablaproductos: this.llenarProductosAsignados(info.productosgenerales),
        tablaagroquimicos: this.llenarAgroquimicosAsignados(info.agroquimicos),

      })

    }
    toast.info("Se borraron los cambios recientes", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })

    setTimeout(() => {
      this.setState({ loadingspinners: false })
    }, 300);

  }

  verModalNotas = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Notas', accionmodal: 1, modalnotasorden: true })
    }
  }
  cerrarModalNotas = (ban) => {
    this.setState({ modalnotasorden: !this.state.modalnotasorden })
    if (ban != 0) {

      this.setState({ listanotas: ban })
    }
  }


  reactivar = async () => {
    this.setState({ loadingspinners: true })

    setTimeout(async () => {
      let x = { "idorden": parseInt(this.state.idorden) }

      var resp = await reactivarOrdenTrabajo(x);


      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      this.componentDidMount();
    }, 300)



  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}



        {(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalproductos) ? (
          <ModalAgregarProductos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalProductos} show={this.state.modalproductos}
          /*activarSpinner={this.activarSpinner}*/ setloadingspinner={this.setLoadingSpinner}
            datosinventarioalmacen={this.state.datosinventarioalmacen}

            idrancho={this.state.idranchoseleccionado}
            productosseleccionados={this.state.tablaproductos.elementos}
          />


          /*<ModalBuscarProducto/>*/
        ) : null}

        {(this.state.modalagroquimicos) ? (
          <ModalAgregarAgroquimicos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalAgroquimicos} show={this.state.modalagroquimicos}
          /*activarSpinner={this.activarSpinner}*/ setloadingspinner={this.setLoadingSpinner}
            datosinventarioalmacen={this.state.datosinventarioalmacen}

            idrancho={this.state.idranchoseleccionado}
            productosseleccionados={this.state.tablaagroquimicos.elementos}
          />


          /*<ModalBuscarProducto/>*/
        ) : null}


        {this.state.modalactividades ? (
          <ModalSeleccionActividades titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalActividades}
            listaactividades={this.state.listaactividades} listatipoactividades={this.state.listatipoactividades}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalactividades} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}

          />
        ) : null}

        {this.state.modaltablas ? (
          <ModalSeleccionTablas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalTablas}
            listalotes={this.state.listalotes} listatablascultivo={this.state.listatablascultivo}
            idlote={this.state.lote.idlote} tablasmarcadas={this.state.tablaasignartablas.elementos}

            idrancho={this.state.idranchoseleccionado}
            tablasseleccionadas={this.state.tablaasignartablas.elementos}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modaltablas} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}

          />
        ) : null}

        {this.state.modalmaquinaria ? (
          <ModalSeleccionMaquinaria titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMaquinaria}
            listamaquinaria={this.state.listamaquinaria} listatipomaquinaria={this.state.listatipomaquinaria}

            idrancho={this.state.idranchoseleccionado}
            maquinariasseleccionadas={this.state.tablamaquinaria.elementos}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalmaquinaria} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
          />
        ) : null}

        {this.state.modalequipo ? (
          <ModalSeleccionEquipo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalEquipo}
            listaequipos={this.state.listaequipos} listatipoequipos={this.state.listatipoequipos}

            idrancho={this.state.idranchoseleccionado}
            equiposseleccionados={this.state.tablaequipos.elementos}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalequipo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
          />
        ) : null}

        {this.state.modalseleccionanio ? (
          <ModalSeleccionAnio titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionAnio} show={this.state.modalseleccionanio}
            accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} anioseleccionado={this.state.anioseleccionado}
          />
        ) : null}

        {this.state.modalmeses ? (
          <ModalSeleccionMeses titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMeses} show={this.state.modalmeses}
            accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} messeleccionado={this.state.messeleccionado}
          />
        ) : null}

        {this.state.modalnotasorden ? (
          <ModalNotasOrden titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalNotas}
            show={this.state.modalnotasorden}
            notas={this.state.listanotas}
            idestatus={this.state.idestatusordenseleccionado}
          />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección de Titulo  ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: '' }}>
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>



              {this.state.idorden == 0 ? (
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Nueva orden</label>
                </div>
              ) : (
                <>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                    <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Orden de trabajo #{this.state.idorden}</label>
                  </div>
                  <div className="col-12 containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    {this.state.plananual == true ? (<label className='lblTexto letraTitulos'>Plan anual {this.state.anioseleccionado}, {this.state.messeleccionado.mes} </label>) : null}
                    {this.state.actividadoperativa == true ? (<label className='lblTexto letraTitulos'>Actividad operativa</label>) : null}
                  </div>
                  {this.state.idestatusordenseleccionado >= 2 && this.state.estatuscambiado == false ? (
                    <div className="col-12 containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                      <label className='lblTexto letraTitulos'>
                        Estatus:   {this.state.listaestatus.map((i, index) => (i.idestatus == this.state.idestatusordenseleccionado ? i.estatus : null))}
                      </label>
                      {(this.state.infoorden.idestatus_orden == 4) ? (
                        <button className='btnNuevo letraNormal' onClick={this.reactivar.bind()}/*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                          style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                        >Reactivar</button>
                      ) : null}
                    </div>
                  ) : (null)}
                  <div className='col-12  containerSubTabla paddingBottomEncabezadoss' style={{ marginTop: "20px" }}>
                  </div>
                  <div className="col-6 containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Zona:</b> {this.state.zona}</label>
                  </div>
                  <div className="col-6  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Rancho:</b> {this.state.rancho}</label>
                  </div>
                  <div className="col-6  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Fecha estimada:</b> {this.state.infoorden.fechaestimada}</label>
                  </div>
                  <div className="col-6  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Duración estimada:</b> {this.state.duracionhoras} horas</label>
                  </div>
                  <div className="col-12  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                    <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Trabajadores sugeridos:</b> {this.state.numeroparticipantes}</label>
                  </div>















                  {(this.state.infoorden.idestatus_orden == 4) ? (



                    <>

                      <div className="col-12  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                        <label className='lblTexto letraTitulos'><b style={{ color: "rgb(35, 113, 253)" }}>Combustibles</b></label>
                      </div>
                      {this.state.listacombustibles.map((comb, index) => (

                        <div className="col-6  containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                          <label className='lblTexto letraTitulos'>{comb.tipo}: {comb.cantidad} L</label>
                        </div>

                      ))}

                    </>


                  ) :


                    /* combustibles */
                    <>
                      <div className='col-12 containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex', marginRight: '15px', marginLeft: '12px' }}>
                        <div className='col-12'>
                          <label className='lblTexto letraTitulos' style={{ paddingLeft: '0px', minWidth: '160px' }}>Combustibles</label>
                        </div>
                      </div>
                      {this.state.listacombustibles.map((comb, index) => (
                        <div className='col-auto containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex', marginRight: '15px', marginLeft: '12px' }}>
                          <div className='col-12'>
                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px', minWidth: '160px' }}>{comb.tipo}: </label>
                            <input id='Search' type="number" onChange={this.escribircombustible(index)} value={comb.cantidad}
                              style={{ color: this.state.colortxtbuscar, maxWidth: '80px', paddingLeft: '2px', marginBottom: '1px' }}
                              className="txtBuscador letraNormal inputOrdenTrabajo"
                              placeholder=""></input>(L)
                          </div>
                          {comb.vermsgerror ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{comb.msgerror}</p> : null}

                        </div>
                      ))}
                    </>








                  }











                  {/*Ver lista estatus*/}
                  {this.state.idestatusordenseleccionado == 1 && this.state.estatuscambiado == false
                    || this.state.idestatusordenseleccionado == 2 && this.state.estatuscambiado == true
                    || this.state.idestatusordenseleccionado == 1 && this.state.estatuscambiado == true ? (
                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, minWidth: '470px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Estatus</label>
                        <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                            onChange={(event) => this.cambiarTipo({ llave: "idestatusordenseleccionado", valor: event.target.value })}>

                            {this.state.listaestatus.map((i, index) => (
                              parseInt(i.idestatus) < 3 ? (
                                <option value={i.idestatus} key={"tipo_estatus_" + index} selected={parseInt(i.idestatus) == parseInt(this.state.idestatusordenseleccionado)} >{i.estatus} </option>
                              ) : null

                            ))
                            }
                          </select>
                        </div>
                      </div>

                    </div>
                  ) : (null)}


                </>


              )}

            </div>
            <br />

          </div>
        </div>
        {/* ////////////////////   Fin Sección de Titulo ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: '' }}>
          <div className="container-fluid anchoMaximo ">




            {this.state.idorden == 0 ? (
              <>
                <div className='row align-items-end contenedorEncabezado'>
                  {/* check de plan anual */}
                  <div className="col-3 col-sm-12 col-md-6 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados checkBoxPlanOT"
                    style={{ marginBottom: '10px', textAlign: "left !important" }}
                    onClick={() => this.setState({ plananual: !this.state.plananual, actividadoperativa: false })}>
                    <input type="checkbox" style={{ minWidth: '30px' }} checked={this.state.plananual} />
                    <label className='lblTexto letraTitulos'>Plan anual</label>
                  </div>

                  {/* check de actividad operativa*/}
                  <div className="col-9 col-sm-6 col-md-6 col-lg-9 col-xl-9 containerSubTabla paddingBottomEncabezados checkBoxPlanOT"
                    style={{ margin: '6px 0px 10px' }}
                    onClick={() => this.setState({ actividadoperativa: !this.state.actividadoperativa, plananual: false })}>
                    <input type="checkbox" style={{ minWidth: '30px' }} checked={this.state.actividadoperativa} />
                    <label className='lblTexto letraTitulos'>Actividad operativa</label>
                  </div>

                  {/* seleccionar zona */}
                  <div className={'col-12 row align-items-end'} style={{ margin: '0px', padding: '0px' }}>
                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, minWidth: '470px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                        <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl, zIndex: 'auto' }}
                            onChange={(event) => this.cambiarTipo({ llave: "idzonaseleccionada", valor: event.target.value })}>
                            <option selected={this.state.idzonaseleccionada == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Selecciona</option>
                            {this.state.listazonas.map((i, index) => (
                              <option value={i.idcatalogo_zona} key={"tipo_zona_" + index} selected={i.idcatalogo_zona == this.state.idzonaseleccionada} >{i.zona} </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {(!this.state.vermsgerroridzona && this.state.vermsgerroridrancho) ? <p className='espacioMsg'></p> : null}

                      {this.state.vermsgerroridzona ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerroridzona}</p> : null}
                    </div>

                    {/* seleccionar rancho */}
                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, minWidth: '470px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                        <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                            onChange={(event) => this.cambiarTipo({ llave: "idranchoseleccionado", valor: event.target.value })}>
                            <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idranchoseleccionado} >
                              {todoslosfiltrosdisponibles == this.state.idzonaseleccionada ? 'Selecciona una zona' : 'Selecciona'}
                            </option>
                            {this.state.listaranchos.filter((item) => item.idcatalogo_zona == this.state.idzonaseleccionada).map((i, index) => (
                              <option value={i.idcatalogo_rancho} key={"tipo_rancho_" + index} selected={i.idcatalogo_rancho == this.state.idranchoseleccionado} >{i.rancho} </option>
                            ))
                            }
                          </select>
                        </div>
                      </div>
                      {this.state.vermsgerroridrancho ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerroridrancho}</p> : null}
                    </div>
                  </div>

                  <div className={'col-' + ((this.state.plananual) ? '12' : 'auto') + ' row align-items-end'} style={{ margin: '0px', padding: '0px' }}>

                    {/* año de plan anual */}
                    {this.state.plananual && <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='contenedorFechaCompras txtFechaAnio'
                        style={{
                          color: this.state.colortxtbuscar,
                          backgroundColor: this.state.fondotxtbuscar,
                          border: this.state.bordertxtbuscar,
                          maxWidth: '158px'
                        }}>
                        <div className='row'>
                          <div className='col-auto wcol-sm-4 wcol-md-4' style={{ padding: '0px 0px 0px 12px' }}>
                            <label className='lblTexto letraTitulos labelFechaa' style={{ color: this.state.colortipolbl }}>Año </label>
                          </div>
                          <div className='col-auto wcol-sm-8 wcol-md-8' style={{ padding: '0px 12px 0px 12px' }}>
                            <DateButton value={this.state.anioseleccionado} onClick={this.verModalSeleccionAnio.bind(this, { opcion: "1" })} />
                          </div>
                        </div>
                      </div>
                    </div>}

                    {/*verModalActividades*/}
                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, minWidth: '470px' }}
                        onClick={this.verModalActividades.bind(this, { opcion: "1" })} >
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Actividad</label>
                        <label className='lblTexto letraTitulos' style={{ color: 'rgb(34, 34, 34, 0.5)', marginLeft: '15px', marginRight: '30px', width: '260px' }}>
                          {this.state.actividadseleccionada != '' ? this.state.actividadseleccionada.actividad : 'Selecciona actividad'}</label>
                        <BiSearchAlt2 style={{ margin: 'auto' }} className="icoBotones" />
                      </div>
                      {this.state.vermsgerroractividad ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerroractividad}</p> : null}
                    </div>

                    {/* mes de plan anual */}
                    {this.state.plananual && <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <div className='contenedorFechaCompras fechaMes'
                        style={{
                          color: this.state.colortxtbuscar,
                          backgroundColor: this.state.fondotxtbuscar,
                          border: this.state.bordertxtbuscar,
                          maxWidth: '258px'
                        }}>
                        <div className='row'>
                          <div className='col-auto wcol-sm-4 wcol-md-4' style={{ padding: '0px 0px 0px 12px' }}>
                            <label className='lblTexto letraTitulos labelFechaa' style={{ color: this.state.colortipolbl }}>Mes </label>
                          </div>
                          <div className='col-auto wcol-sm-8 wcol-md-8' style={{ padding: '0px 12px 0px 12px' }}>
                            <DateMonthButton value={this.state.messeleccionado.mes} onClick={this.verModalMeses} />
                          </div>
                        </div>
                      </div>
                      {this.state.vermsgerrormesseleccionado ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrormesseleccionado}</p> : null}
                    </div>}
                  </div>

                  {/* selecciona fecha de la orden  */}
                  <div className={'col-' + ((!this.state.plananual) ? '6' : 'auto') + ' containerSubTabla paddingBottomEncabezados'} style={{ marginRight: '15px' }}>
                    <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, maxWidth: '320px', maxHeight: '34px' }}>
                      <div className='row'>
                        <div className=' col-sm-4 col-md-4'>
                          <label className='lblTexto letraTitulos labelFechaOrden' style={{ color: this.state.colortipolbl, }}>Fecha: </label>
                        </div>
                        <div className=' col-sm-8 col-md-8'>
                          <DatePicker
                            selected={this.state.fechaprincipal}
                            onChange={(date) => this.cambiarFecha({ llave: "fechaprincipal", valor: date })}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={
                              <DateButton />
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.vermsgerrorfecha ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrorfecha}</p> : null}
                  </div>

                  {/*Ver lista estatus*/}
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, minWidth: '470px' }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Estatus</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.cambiarTipo({ llave: "idestatusordenseleccionado", valor: event.target.value })}>

                          {this.state.listaestatus.map((i, index) => (

                            i.idestatus != 3 ? (
                              <option value={i.idestatus} key={"tipo_estatus_" + index} selected={i.idestatus == this.state.idestatusordenseleccionado} >{i.estatus} </option>
                            ) : null

                          ))
                          }
                        </select>
                      </div>
                    </div>
                    {/*this.state.vermsgerroridrancho ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerroridrancho}</p> : null*/}
                  </div>

                  {/* duración de la actividad */}
                  <div className='col-auto containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex', marginRight: '15px', marginLeft: '12px' }}>
                    <div className='col-12'>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px', minWidth: '160px' }}>Duración (horas): </label>
                      <input id='Search' type="number" onChange={this.escribir("duracionhoras")} value={this.state.duracionhoras}
                        style={{ color: this.state.colortxtbuscar, maxWidth: '80px', paddingLeft: '2px', marginBottom: '1px' }}
                        className="txtBuscador letraNormal inputOrdenTrabajo"
                        placeholder=""></input>
                    </div>
                    {this.state.vermsgerrorduracionhoras ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrorduracionhoras}</p> : null}
                  </div>

                  {/* participantes */}
                  <div className='col-auto containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex' }}>
                    <di className='col-12'>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px', minWidth: '230px' }}>Número de participantes: </label>
                      <input id='Search' type="number" onChange={this.escribir("numeroparticipantes")} value={this.state.numeroparticipantes}
                        style={{ color: this.state.colortxtbuscar, maxWidth: '80px', paddingLeft: '2px', marginBottom: '1px' }}
                        className="txtBuscador letraNormal inputOrdenTrabajo"
                        placeholder=""></input>
                    </di>
                    {this.state.vermsgerrornumeroparticipantes ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrornumeroparticipantes}</p> : null}
                  </div>

                  {/* combustibles */}
                  <div className='col-12 containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex', marginRight: '15px', marginLeft: '12px' }}>
                    <div className='col-12'>
                      <label className='lblTexto letraTitulos' style={{ paddingLeft: '0px', minWidth: '160px' }}>Combustibles</label>
                    </div>
                  </div>
                  {this.state.listacombustibles.map((comb, index) => (
                    <div className='col-auto containerSubTabla paddingBottomEncabezadoss row align-items-end' style={{ padding: '6px 0px 6px', display: 'flex', marginRight: '15px', marginLeft: '12px' }}>
                      <div className='col-12'>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px', minWidth: '160px' }}>{comb.tipo}: </label>
                        <input id='Search' type="number" onChange={this.escribircombustible(index)} value={comb.cantidad}
                          style={{ color: this.state.colortxtbuscar, maxWidth: '80px', paddingLeft: '2px', marginBottom: '1px' }}
                          className="txtBuscador letraNormal inputOrdenTrabajo"
                          placeholder=""></input>(L)
                      </div>
                      {comb.vermsgerror ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{comb.msgerror}</p> : null}

                    </div>
                  ))}

                  <div style={{ marginBottom: '15px' }}></div>

                </div>
                <br />
                <br />
              </>

            ) : (
              null
            )}



















          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}




        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo" style={{ textAlign: "right" }}>
            <button className='btnNuevo letraNormal' onClick={this.verModalNotas.bind(this, { opcion: "1" })}/*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
              style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
            >Notas ({this.state.listanotas.length})</button>
          </div>
        </div>

        {/* ////////////////////////////////////////   Inicio componente descripción   //////////////////////////////////////////*/}
        {/* Descripción */}
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Descripción </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ padding: '0px', margina: '0px' }}>
            <textarea className='areaTextoModalOT letraNormal ' rows="4" id='comentarios'
              value={this.state.txtdescripcion} onChange={this.escribirTextArea("txtdescripcion")}></textarea>

            {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
              style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}

          </div>
        </div>
        {/* ////////////////////////////////////////   Final componente descripción   //////////////////////////////////////////*/}




        {/* ////////////////////////////////////////   Inicio tabla Asignar Tablas   //////////////////////////////////////////*/}
        {/* Descripción */}

        <br />
        <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Asignar tablas </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #0061fe', borderRadius: '5px', minWidth: '1005px' }}>
            <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
              <div className="col-auto">
                {/*<label className="lblTexto letraTitulos">Nombre lote xyz</label>*/}
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                <label className='lblTexto letraTitulos' >{this.state.lote.lote}</label>
              </div>
              <div className='col '></div>

              {this.state.actividadoperativa == false && this.state.idestatusordenseleccionado != 4
                || this.state.actividadoperativa == true && this.state.requieretablas == true && this.state.idestatusordenseleccionado != 4 ? (
                <div className="col-auto align-items-end">
                  <button className='btnNuevo letraNormal' onClick={this.verModalTablas.bind(this, { opcion: "1" })}/*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  >Agregar</button>
                </div>
              ) : null}

            </div>


            {this.state.actividadoperativa && this.state.idestatusordenseleccionado != 4 ? (
              <>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                  <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                    style={{ margin: 'auto' }}
                    onClick={() => this.setState({ requieretablas: false })}>
                    <input type="radio" style={{ minWidth: '30px' }} checked={!this.state.requieretablas} />
                    <label className='lblTexto letraTitulos'>Sin tablas</label>
                  </div>
                  <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                    style={{ margin: 'auto' }}
                    onClick={() => this.setState({ requieretablas: true })}>
                    <input type="radio" style={{ minWidth: '30px' }} checked={this.state.requieretablas} />
                    <label className='lblTexto letraTitulos'>Requiere tablas</label>
                  </div>
                  <div className='col '></div>
                </div>
              </>

            ) : null}


            {this.state.requieretablas == true && this.state.actividadoperativa == 1 ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaasignartablas.encabezados.map((titulo, index) => (
                        <th key={"tabla_tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaasignartablas.mindimensiones[index],
                            width: this.state.tablaasignartablas.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablaasignartablas.elementos.map((item, index) => (
                      <tr key={"tabla_seleccionada" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[0], width: this.state.tablaasignartablas.dimensiones[0] }}>
                          {item.tabla}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[1], width: this.state.tablaasignartablas.dimensiones[1] }}>
                          {item.estatus_siembra}
                          {item.estatuscultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[2], width: this.state.tablaasignartablas.dimensiones[2], textAlign: 'right' }}>
                          {item.areatotal}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[3], width: this.state.tablaasignartablas.dimensiones[3], textAlign: 'right' }}>
                          {item.plantastotales}
                        </td>
                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablaasignartablas.mindimensiones[4], width: this.state.tablaasignartablas.dimensiones[4], textAlign: 'center', padding: '3px 8px' }}>
                            {/*<button className='btnEditar' /*onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}* / style={{
                         backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                       }}><BiEdit className="icoBotones" /> Editar</button>*/}
                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablaasignartablas", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>
                        ) : null
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : (null)}

            {this.state.actividadoperativa == 0 ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaasignartablas.encabezados.map((titulo, index) => (
                        <th key={"tabla_seleccionada" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaasignartablas.mindimensiones[index],
                            width: this.state.tablaasignartablas.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablaasignartablas.elementos.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[0], width: this.state.tablaasignartablas.dimensiones[0] }}>
                          {item.tabla}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[1], width: this.state.tablaasignartablas.dimensiones[1] }}>
                          {item.estatus_siembra}
                          {item.estatuscultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[2], width: this.state.tablaasignartablas.dimensiones[2], textAlign: 'right' }}>
                          {item.areatotal}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[3], width: this.state.tablaasignartablas.dimensiones[3], textAlign: 'right' }}>
                          {item.plantastotales}
                        </td>
                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablaasignartablas.mindimensiones[4], width: this.state.tablaasignartablas.dimensiones[4], textAlign: 'center', padding: '3px 8px' }}>
                            {/*<button className='btnEditar' /*onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}* / style={{
                         backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                       }}><BiEdit className="icoBotones" /> Editar</button>*/}

                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablaasignartablas", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : (null)}


          </div>
        </div>

        {/* ////////////////////////////////////////   Final tabla Asignar Tablas   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla Selección Maquinaria   //////////////////////////////////////////*/}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Selecciona maquinaria </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #0061fe', borderRadius: '5px', minWidth: '1005px' }}>

            {this.state.idestatusordenseleccionado != 4 ? (
              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                {/*<div className="col-auto">
                {/*<label className="lblTexto letraTitulos">Nombre lote xyz</label>* /}
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                <label className='lblTexto letraTitulos' >Nombre lote xyz</label>
              </div>*/}
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requieremaquinaria: false })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={!this.state.requieremaquinaria} />
                  <label className='lblTexto letraTitulos'>Sin maquinaria</label>
                </div>
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requieremaquinaria: true })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={this.state.requieremaquinaria} />
                  <label className='lblTexto letraTitulos'>Requiere maquinaria</label>
                </div>
                <div className='col '></div>
                <div className="col-auto align-items-end">
                  {this.state.requieremaquinaria == true ? (
                    <button className='btnNuevo letraNormal' onClick={this.verModalMaquinaria.bind(this, { opcion: "1" })}
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    >Agregar</button>
                  ) : null}

                </div>
              </div>
            ) : null
            }


            {this.state.requieremaquinaria == true ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablamaquinaria.encabezados.map((titulo, index) => (
                        <th key={"tabla_maquinaria" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablamaquinaria.mindimensiones[index],
                            width: this.state.tablamaquinaria.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablamaquinaria.elementos.map((item, index) => (
                      <tr key={"tabla_maquinaria" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[0], width: this.state.tablamaquinaria.dimensiones[0] }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[1], width: this.state.tablamaquinaria.dimensiones[1] }}>
                          {item.tipomaquinaria}
                        </td>

                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablamaquinaria.mindimensiones[2], width: this.state.tablamaquinaria.dimensiones[2], textAlign: 'center', padding: '3px 8px' }}>
                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablamaquinaria", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>
                        ) : null
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : null}

          </div>
        </div>
        {/* ////////////////////////////////////////   Final tabla Selección Maquinaria   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla Selección Equipos   //////////////////////////////////////////*/}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Selecciona equipo </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #0061fe', borderRadius: '5px', minWidth: '1005px' }}>
            {this.state.idestatusordenseleccionado != 4 ? (
              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                {/*<div className="col-auto">
                {/*<label className="lblTexto letraTitulos">Nombre lote xyz</label>* /}
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                <label className='lblTexto letraTitulos' >Nombre lote xyz</label>
              </div>*/}
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereequipo: false })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={!this.state.requiereequipo} />
                  <label className='lblTexto letraTitulos'>Sin equipo</label>
                </div>
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereequipo: true })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={this.state.requiereequipo} />
                  <label className='lblTexto letraTitulos'>Requiere equipo</label>
                </div>
                <div className='col '></div>
                <div className="col-auto align-items-end">
                  {this.state.requiereequipo == true ? (
                    <button className='btnNuevo letraNormal' onClick={this.verModalEquipo.bind(this, { opcion: "1" })}/*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    >Agregar</button>
                  ) : null}
                </div>
              </div>
            ) : null
            }

            {this.state.requiereequipo == true ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaequipos.encabezados.map((titulo, index) => (
                        <th key={"tabla_equipo" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaequipos.mindimensiones[index],
                            width: this.state.tablaequipos.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablaequipos.elementos.map((item, index) => (
                      <tr key={"tabla_equipo" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[0], width: this.state.tablaequipos.dimensiones[0] }}>

                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[1], width: this.state.tablaequipos.dimensiones[1] }}>
                          {
                            item.tipoequipo
                          }
                        </td>
                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablaequipos.mindimensiones[2], width: this.state.tablaequipos.dimensiones[2], textAlign: 'center', padding: '3px 8px' }}>
                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablaequipos", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>) : null
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : null}

          </div>
        </div>
        {/* ////////////////////////////////////////   Final tabla Selección Equipos   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla Selección Productos   //////////////////////////////////////////*/}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Selecciona productos </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #0061fe', borderRadius: '5px', minWidth: '1005px' }}>
            {this.state.idestatusordenseleccionado != 4 ? (
              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                {/*<div className="col-auto">
                {/*<label className="lblTexto letraTitulos">Nombre lote xyz</label>* /}
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                <label className='lblTexto letraTitulos' >Nombre lote xyz</label>
              </div>*/}
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereproductos: false })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={!this.state.requiereproductos} />
                  <label className='lblTexto letraTitulos'>Sin productos</label>
                </div>
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereproductos: true })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={this.state.requiereproductos} />
                  <label className='lblTexto letraTitulos'>Requiere productos</label>
                </div>
                <div className='col '></div>
                <div className="col-auto align-items-end">
                  {this.state.requiereproductos == true ? (
                    <button className='btnNuevo letraNormal' onClick={this.verModalProductos.bind(this, { opcion: "1" })}
                      /*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    >Agregar</button>
                  ) : null}
                </div>
              </div>) : null
            }

            {this.state.requiereproductos == true ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaproductos.encabezados.map((titulo, index) => (
                        <th key={"tabla_prod" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaproductos.mindimensiones[index],
                            width: this.state.tablaproductos.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablaproductos.elementos.map((item, index) => (
                      <tr key={"tabla_prod" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[1], width: this.state.tablaproductos.dimensiones[1] }}>
                          {item.nombrecomercial}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[2], width: this.state.tablaproductos.dimensiones[2], textAlign: 'center' }}>
                          {item.claveunidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[3], width: this.state.tablaproductos.dimensiones[3], textAlign: 'right' }}>
                          {item.cantidad}
                        </td>
                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablaproductos.mindimensiones[4], width: this.state.tablaproductos.dimensiones[4], textAlign: 'center', padding: '3px 8px' }}>
                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablaproductos", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>) : null
                        }

                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : null}

          </div>
        </div>
        {/* ////////////////////////////////////////   Final tabla Selección Productos   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla Selección Agroquímicos   //////////////////////////////////////////*/}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Selecciona agroquímicos </label>
          </div>
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #0061fe', borderRadius: '5px', minWidth: '1005px' }}>
            {this.state.idestatusordenseleccionado != 4 ? (
              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>

                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereagroquimicos: false })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={!this.state.requiereagroquimicos} />
                  <label className='lblTexto letraTitulos'>Sin agroquímicos</label>
                </div>
                <div className="col-auto paddingBottomEncabezados checkBoxPlanOT"
                  style={{ margin: 'auto' }}
                  onClick={() => this.setState({ requiereagroquimicos: true })}>
                  <input type="radio" style={{ minWidth: '30px' }} checked={this.state.requiereagroquimicos} />
                  <label className='lblTexto letraTitulos'>Requiere agroquímicos</label>
                </div>
                <div className='col '></div>
                <div className="col-auto align-items-end">
                  {this.state.requiereagroquimicos == true ? (
                    <button className='btnNuevo letraNormal' onClick={this.verModalAgroquimicos.bind(this, { opcion: "1" })}
                      /*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    >Agregar</button>
                  ) : null}
                </div>
              </div>) : null
            }

            {this.state.requiereagroquimicos == true ? (
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaagroquimicos.encabezados.map((titulo, index) => (
                        <th key={"tabla_agro" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaagroquimicos.mindimensiones[index],
                            width: this.state.tablaagroquimicos.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.tablaagroquimicos.elementos.map((item, index) => (
                      <tr key={"tabla_agro" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaagroquimicos.mindimensiones[1], width: this.state.tablaagroquimicos.dimensiones[1] }}>
                          {item.nombrecomercial}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaagroquimicos.mindimensiones[2], width: this.state.tablaagroquimicos.dimensiones[2], textAlign: 'center' }}>
                          {item.claveunidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaagroquimicos.mindimensiones[3], width: this.state.tablaagroquimicos.dimensiones[3], textAlign: 'center' }}>
                          {item.cantidad}
                        </td>
                        {this.state.idestatusordenseleccionado != 4 ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tablaagroquimicos.mindimensiones[4], width: this.state.tablaagroquimicos.dimensiones[4], textAlign: 'center', padding: '3px 8px' }}>
                            <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar("tablaagroquimicos", index)}>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>) :
                          null}
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            ) : null}

          </div>
        </div>
        {/* ////////////////////////////////////////   Final tabla Selección Agroquímicos   //////////////////////////////////////////*/}














        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}

        <>
          <br />
          <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
            <br />
            {this.state.idorden == 0 ?
              (<div className="divBtnContenedorRequisicionOT" >
                <Button className="btnRechazar" onClick={this.cancelarFormulario}>Cancelar</Button>
                <Button className="btnAceptar" onClick={() => this.guardarCambios()}>Guardar</Button>
              </div>) : BsNutFill
            }
            {this.state.idorden != 0 && this.state.infoorden.idestatus_orden != 4 ?
              (<div className="divBtnContenedorRequisicionOT" >
                <Button className="btnRechazar" onClick={this.cancelarFormulario}>Cancelar</Button>
                <Button className="btnAceptar" onClick={() => this.guardarCambios()}>Guardar</Button>
              </div>) : null
            }
            <br />
          </div>
        </>

        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}

      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    {value} <FaCalendar className="icoBotones" />
  </button>
));

const DateMonthButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '120px', cursor: 'pointer' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordentrabajo"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
